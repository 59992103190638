import React, { useRef } from 'react';
import useUser from '../hooks/useUser';
import useLoading from '../hooks/useLoading';
import { useEffect, useState } from 'react';
import Video from '../classes/Video';
import { IVideoContent } from '../interfaces/contentfulModels';
import MuxPlayer, { MuxPlayerRefAttributes } from '@mux/mux-player-react';

const MuxContentfulVideoPlayer = ({ video }: { video: IVideoContent }) => {
  const { user } = useUser();
  const { loading, startLoading, stopLoading } = useLoading();
  const [v, setV] = useState<Video | null>();
  const player = useRef<MuxPlayerRefAttributes>();

  useEffect(() => {
    startLoading();
    Video.get(video.fields.video_id).then((v) => {
      setV(v);
      stopLoading();
    });
  }, []);

  return v ? (
    <MuxPlayer
      ref={player}
      autoPlay={false}
      thumbnailTime={0}
      streamType={'on-demand'}
      metadataViewerUserId={user?.hash}
      metadataVideoId={v.hash ?? ''}
      metadataVideoTitle={v.fileName ?? ''}
      playbackId={v.muxPlaybackId ?? ''}
    />
  ) : (
    <p>Initiating video player</p>
  );
};

export default MuxContentfulVideoPlayer;
