import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import User from '../../classes/User';
import { EyeVisible, EyeHidden } from '../../assets/img/icons';

import Alert, { AlertType } from '../../components/Alert';

import { useAppSelector } from '../../hooks/redux';
import { onboard, signup } from '../../actions/user.action';
import { clearErrors } from '../../reducers/user.reducer';

import { identifySegment } from '../../hooks/useSegment';
import { Event } from '../../constants/SegmentEvents';
import useLoading from '../../hooks/useLoading';
import useSearchParams from '../../hooks/useSearchParams';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

import { ISignupForm } from '../../interfaces/contentfulModels';
import { getYear, parse, startOfYear, subYears } from 'date-fns';
import clsx from 'clsx';
import 'animate.css';
import ReactPixel from 'react-facebook-pixel';
import { parseAsync } from '@babel/core';

const OptinPage = ({ form }: { form: ISignupForm }): JSX.Element => {
  const random = (Math.random() * 100000).toFixed();
  const randomFieldId = `option-form-${random}`;

  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.user.error);

  // Password
  const [passwordShown, showPassword] = useState(false);

  const [checkResponse, setCheckResponse] = useState<
    CheckAccountResponse | undefined
  >();

  const { startLoading, stopLoading, loading } = useLoading();

  const navigation = useHistory();
  const search = useSearchParams<{ parrain: string }>();

  useEffect(() => {
    if (currentUser) {
      //@ts-ignore
      identifySegment(currentUser);
      Event.SIGN_UP.track();
      Event.COMPLETE_FORM_FRONT.track();
      navigation.push('/mon-challenge-offert');
    }
  }, [currentUser, error]);

  useEffect(() => {
    if (form.fields.backgroundColor) {
      document.documentElement.style.setProperty(
        '--form-background-color',
        form.fields.backgroundColor,
      );
    }

    if (form.fields.ctaColor) {
      document.documentElement.style.setProperty(
        '--form-cta-background-color',
        form.fields.ctaColor,
      );
    }

    if (form.fields.ctaTextColor) {
      document.documentElement.style.setProperty(
        '--form-cta-text-color',
        form.fields.ctaTextColor,
      );
    }
  }, [form]);

  const CheckResponses: FC = () => {
    if (checkResponse.error) {
      return (
        <span className={'font-medium text-red-600 flex gap-1 items-center'}>
          <ExclamationTriangleIcon className={'w-5 h-5'} />{' '}
          {checkResponse.error}
        </span>
      );
    } else if (checkResponse.data.suggestion) {
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationCircleIcon className={'w-5 h-5'} /> Hum, ne serait-ce pas
          plutôt{' '}
          <strong>
            {checkResponse.data.local}@{checkResponse.data.suggestion}
          </strong>{' '}
          ?
        </span>
      );
    } else if (
      checkResponse.data.checks.domain.is_suspected_disposable_address
    ) {
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationTriangleIcon className={'w-5 h-5 shrink-0'} /> Aïe, il
          semblerait que ce soit une addresse email jetable. Vous ne pouvez pas
          vous inscrire avec cette addresse !
        </span>
      );
    } else if (
      checkResponse.data.verdict === 'Risky' ||
      checkResponse.data.verdict === 'Invalid'
    ) {
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationCircleIcon className={'w-5 h-5 shrink-0'} /> Attention,
          vous pourriez avoir des difficultés à recevoir nos emails.
        </span>
      );
    } else {
      return <div />;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    startLoading();
    e.preventDefault();
    const formSelector = document.getElementById(
      randomFieldId,
    ) as HTMLFormElement;
    const formData = new FormData(formSelector);
    const record: Record<string, any> = {};
    // @ts-ignore
    for (const pair of formData.entries()) {
      record[pair[0]] = pair[1];
    }

    record['birthday'] = parse(record['birthday'], 'yyyy-MM-dd', new Date());
    record['metadata'] = {
      provider: record['provider'],
      postal_code: record['postal_code'],
      tos: new Date(),
    };

    dispatch(onboard(record));
    await new Promise((resolve) => setTimeout(resolve, 2500));
    stopLoading();
  };

  return (
    <form
      id={randomFieldId}
      className={
        'sm:overflow-y-visible bg-optinForm-backgroundColor p-8 -ml-6 my-8'
      }
      onSubmit={handleSubmit}
    >
      <div className={'w-11/12 md:w-1/2 mx-auto'}>
        {form.fields.gender && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <select
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
              name={'gender'}
            >
              <option value={'female'}>Une femme</option>
              <option value={'male'}>Un homme</option>
              <option value={'undefined'}>Non genré</option>
            </select>
          </div>
        )}

        <div className={'flex flex-col md:flex-row gap-4'}>
          {form.fields.firstname && (
            <div className={'flex-1 flex flex-col border-2 rounded p-3 mb-4'}>
              <input
                name={'firstname'}
                required
                type={'text'}
                autoComplete={'given_name'}
                placeholder={'Votre prénom...'}
                className={
                  'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
                }
              />
            </div>
          )}
          {form.fields.lastname && (
            <div className={'flex-1 flex flex-col border-2 rounded p-3 mb-4'}>
              <input
                name={'lastname'}
                type={'text'}
                autoComplete={'given_name'}
                placeholder={'Votre nom...'}
                className={
                  'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
                }
              />
            </div>
          )}
        </div>
        {form.fields.email && (
          <div className={'mb-4'}>
            <div className={'flex flex-col border-2 rounded p-3 mb-4'}>
              <input
                name={'email'}
                required
                type={'email'}
                autoComplete={'email'}
                placeholder={'Votre adresse email...'}
                className={
                  'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
                }
                onBlur={async (event) => {
                  if (
                    event.target.value.length > 5 &&
                    event.target.value.includes('@')
                  ) {
                    const user = new User();
                    user.email = event.target.value;
                    const checks = await user.checkAccount();
                    setCheckResponse(checks);
                  }
                }}
              />
            </div>
            {checkResponse && <CheckResponses />}
          </div>
        )}

        {form.fields.password && (
          <div className={'flex flex-col border-2 rounded p-3 mb-4'}>
            <div className={'flex flex-1 items-center'}>
              <input
                name={'password'}
                required
                type={passwordShown ? 'text' : 'password'}
                placeholder={'Choisir un mot de passe...'}
                className={
                  'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
                }
              />
              {passwordShown ? (
                <EyeHidden
                  className={'text-gray-500 w-6 h-6'}
                  onClick={() =>
                    showPassword((passwordShown) => !passwordShown)
                  }
                />
              ) : (
                <EyeVisible
                  className={'text-gray-500 w-6 h-6'}
                  onClick={() =>
                    showPassword((passwordShown) => !passwordShown)
                  }
                />
              )}
            </div>
          </div>
        )}

        {form.fields.postalCode && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <input
              name={'postal_code'}
              minLength={5}
              type={'text'}
              required={true}
              autoComplete={'postal-code'}
              placeholder={'Votre code postal'}
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
            />
          </div>
        )}

        {form.fields.birthday && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <input
              name={'birthday'}
              type={'date'}
              placeholder={'Date de naissance'}
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
            />
          </div>
        )}

        {form.fields.phoneNumber && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <input
              name={'phone_number'}
              type={'tel'}
              placeholder={'Votre numéro de téléphone...'}
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
            />
          </div>
        )}

        {form.fields.ageRank && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <select
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
              name={'birth_year'}
            >
              {Array(65)
                .fill({})
                .map((v, i) => (
                  <option
                    value={getYear(subYears(startOfYear(new Date()), i))}
                    key={getYear(subYears(startOfYear(new Date()), i))}
                  >
                    {getYear(subYears(startOfYear(new Date()), i + 35))}
                  </option>
                ))}
            </select>
          </div>
        )}

        {search.parrain && (
          <div className={'flex flex-1 flex-col border-2 rounded p-3 mb-4'}>
            <span className={'text-slate-500 text-sm mb-1.5'}>
              Code de parrainage
            </span>
            <input
              name={'sponsor_code'}
              type={'text'}
              placeholder={'MON CODE DE REDUCTION'}
              value={search.parrain}
              className={
                'border-none p-0 bg-transparent outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
              }
            />
          </div>
        )}

        {form.fields.submitEventName && (
          <input
            name={'event'}
            type={'hidden'}
            value={form.fields.submitEventName}
          />
        )}

        {form.fields.providerName && (
          <input
            name={'provider'}
            type={'hidden'}
            value={form.fields.providerName}
          />
        )}

        {form.fields.checkboxTos && (
          <div className={'flex items-center gap-2 my-3'}>
            <input
              required
              name={'tos_accepted'}
              type={'checkbox'}
              className={
                'border border-slate-500 rounded text-teal-600 hover:ring-teal-600 focus:ring-teal-600'
              }
            />{' '}
            <div
              className={
                'inline-flex flex-wrap gap-1 text-gray-600 text-sm font-base'
              }
            >
              En m'inscrivant, j'accepte
              <a
                href={'/legal/cgv'}
                className={
                  'font-medium underline decoration-teal-600 underline-offset-2'
                }
              >
                les CGVU
              </a>
              et
              <a
                href={'/legal/privacy'}
                className={
                  'font-medium underline underline-offset-2 decoration-teal-600'
                }
              >
                la Politique de confidentialité
              </a>
              de Rester Jeune.
            </div>
          </div>
        )}

        <button
          className={clsx(
            'shadow-md bg-optinForm-ctaBackground w-full items-center justify-center p-2.5 py-4 flex flex-col flex-1 rounded-full disabled:bg-gray-300 disabled:text-gray-800 my-8',
            !loading &&
              form.fields.ctaAnimation &&
              `animate__animated animate__${form.fields.ctaAnimation} animate__infinite`,
          )}
          type={'submit'}
          disabled={loading}
        >
          {!loading ? (
            <>
              <span
                className={
                  'font-oswald text-optinForm-ctaTitle text-lg md:text-xl'
                }
              >
                {form.fields.ctaTitle}
              </span>
              {form.fields.ctaSubtitle && (
                <span
                  className={
                    'font-sans text-optinForm-ctaTitle text-md md:text-lg'
                  }
                >
                  {form.fields.ctaSubtitle}
                </span>
              )}
            </>
          ) : (
            <>
              <span
                className={
                  'font-oswald text-optinForm-ctaTitle text-lg md:text-xl'
                }
              >
                Inscription en cours
              </span>
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default OptinPage;
